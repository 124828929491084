import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";


const DatasourceClio = (props) => {

    const AuthUrl = "https://app.clio.com/oauth/authorize" +
        "?client_id=1R9HmkUJBCq4oy2TS02lxTKs1foVAHy3C0F7Dt4o" +
        "&redirect_uri=https://auth.cloudcti.nl/webapi/oauth2" +
        "&response_type=code"
    const TokenUrl = "https://app.clio.com/oauth/token" 
    const TokenBody = "grant_type=authorization_code&client_id=1R9HmkUJBCq4oy2TS02lxTKs1foVAHy3C0F7Dt4o&client_secret=IKN8uVnMYUL5znGpdWhCvrtZZcoWS06B8dvWQkIa&code={code}&redirect_uri=https://auth.cloudcti.nl/webapi/oauth2" 
    const TokenBodyType = "application/x-www-form-urlencoded"
    const TokenCodeInBody = false
    const TokenCodeReplace = true
    
    return (<DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={AuthUrl} TokenUrl={TokenUrl} TokenBody={TokenBody} TokenBodyType={TokenBodyType} TokenCodeInBody={TokenCodeInBody} TokenCodeReplace={TokenCodeReplace} OnSetReady={props.OnSetReady}/>)
}

DatasourceClio.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceClio